import React from 'react';
import classes from './Navigation.module.css';
import { NavLink } from 'react-router-dom';

const NavigationItems = () => {

    return (
        <nav className={classes.navContainer}>
            <ul className={classes.navList}>
                <li>
                    <NavLink
                        to='/'
                        className={classes.navItem}
                        activeClassName={classes.currentPage}
                        exact
                    >
                        Home
                    </NavLink>
                </li>
                    |
                    <li>
                    <NavLink
                        to='/portfolio'
                        className={classes.navItem}
                        activeClassName={classes.currentPage}
                        exact
                    >
                        My Work
                    </NavLink>
                </li>
                    |
                    <li>
                    <NavLink
                        to='/blog'
                        className={classes.navItem}
                        activeClassName={classes.currentPage}
                    // exact
                    >
                        Blog
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}

export default NavigationItems;