import React, { useState, useEffect } from 'react';
import classes from '../../index.module.css';
import homeClasses from './Home.module.css';

import gitHubLogo from '../../assets/GitHub-Mark-64px.png';
import linkedInLogo from '../../assets/LI-In-Bug.png';

import PortfolioCard from '../../components/Cards/PortfolioCard/PortfolioCard';
import BlogCard from '../../components/Cards/BlogCard/BlogCard';
import Spinner from '../../components/UI/Loading/Spinner';

const Home = (props) => {

    //___________________________________________________
    //---------------------------------------------------
    //                         STATE 
    //
    const [latestWorkloading, setLatestWorkLoading] = useState(true);
    const [latestBlogloading, setLatestBlogLoading] = useState(true);

    //make sure that loading is shown latestwork is not loaded yet
    useEffect(() => {
        if (props.latestWork) {
            setLatestWorkLoading(false);
        }
    }, [props.latestWork])

    useEffect(() => {
        if (props.latestBlog) {
            setLatestBlogLoading(false);
        }
    }, [props.latestBlog])


    //___________________________________________________
    //---------------------------------------------------
    //                         JSX 
    //
    return (
        <div className={classes.backgroundCard}>
            <section>
                <h1 className={classes.title}>
                    Mihai Robu
                </h1>
                <a className={homeClasses.link} href="https://www.linkedin.com/in/mihai-robu/"><img src={linkedInLogo} alt="LinkedIn" height="32" /></a>
                <a className={homeClasses.link} href="https://github.com/mihai-robu"><img src={gitHubLogo} alt="GitHub" width="32" height="32" /></a>
                <hr />
                <p className={homeClasses.about}>
                    Software developer with passion for all things tech!
                    <br />
                    <br />
                    This is a place where I'll be posting about my experiences and
                    thoughts about different topics in the tech world - anything from
                    new languages and frameworks to AR/VR to current and past projects.
                </p>
            </section>
            <hr />
            <section>
                <h2 className={classes.subTitle}>
                    The Latest
                </h2>
                <div className={homeClasses.latestContainer}>
                    {latestWorkloading ? <Spinner /> :
                        <PortfolioCard
                            id={props.latestWork.id}
                            imagePath={props.latestWork.imgSrc}
                            title={props.latestWork.title}
                            description={props.latestWork.description}
                            isAuthenticated={props.latestWork.isAuthenticated}
                            homePage={true}
                        />
                    }
                    {latestBlogloading ? <Spinner /> :
                        <BlogCard
                            id={props.latestBlog.id}
                            month={props.latestBlog.month}
                            day={props.latestBlog.day}
                            year={props.latestBlog.year}
                            title={props.latestBlog.title}
                            content={props.latestBlog.content}
                            homePage={true}
                        />
                    }
                </div>
            </section>
        </div >
    )
}

export default Home;