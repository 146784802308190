import React from 'react';
import classes from './Widgets.module.css';

export const CloseWidget = (props) => {
    return (
        <div
            className={classes.closeButton}
            onClick={props.clickHandler}>
            <i className="far fa-times-circle"></i>
        </div>
    );
}

export const AddWidget = (props) => {
    return (
        <div
            className={classes.addButton}
            onClick={props.clickHandler}>
            <i className="fas fa-plus-circle"></i>
        </div>
    );
}

export const EditWidget = (props) => {
    return (
        <div
            className={classes.editButton}
            onClick={props.clickHandler}>
            <i className="fas fa-pen"></i>
        </div>
    );
}

export const SaveWidget = (props) => {
    return (
        <div
            className={classes.saveButton}
            onClick={props.clickHandler}>
            <i className="fas fa-save"></i>
        </div>
    );
}

export const DeleteWidget = (props) => {
    return (
        <div
            className={classes.deleteButton}
            onClick={props.clickHandler}>
            <i className="fas fa-trash-alt"></i>
        </div>
    );
}

