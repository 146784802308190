import React, { useState } from 'react';
import classes from '../../index.module.css';
import blogClasses from './Blog.module.css';

import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import BlogCard from '../../components/Cards/BlogCard/BlogCard';
import BlogEntry from '../../components/BlogEntry/BlogEntry';
import { AddWidget } from '../../components/UI/Widgets/Widgets';

const Blog = (props) => {

    //___________________________________________________
    //                 [[[  STATE  ]]]
    //
    // For Routing purposes
    const { path } = useRouteMatch();
    // used in JSX to switch the title from "Blog" to the entry's title
    const location = useLocation().pathname;

    const [showNewBlogModal, toggleShowNewBlogModal] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('Blog.');

    //___________________________________________________
    //                 [[[  TOGGLES  ]]]
    //

    // used to show the modal for adding a new blog entry
    const toggleNewBlogEntry = () => toggleShowNewBlogModal(!showNewBlogModal);

    // Passed into each BlogCard so that onClick it can switch the
    //  the title from "Blog" to it's title
    const switchTitle = (newTitle) => setCurrentTitle(newTitle);

    //___________________________________________________
    //                    [[[  JSX  ]]]
    //

    // NOTE: This is pretty hacky. 
    //  I'm trying to render a list of cards for each blog entry, 
    //   however, when they are clicked I want to navigate to 
    //   a page that shows the entire blog entry.
    // I don't really know how to do this properly without returning
    //  a <Switch> tag that includes both routes...
    const entryCardList = props.blogEntries.map((entry) => {
        const entryTitleURL = entry.title.replace(/\s/g, "-").toLowerCase();
        return (<li className={blogClasses.blogListItem} key={entry.id}>
            <Switch>
                <Route exact path={path} component={() =>
                    <BlogCard
                        id={entry.id}
                        month={entry.month}
                        day={entry.day}
                        year={entry.year}
                        title={entry.title}
                        content={entry.content}
                        switchTitle={switchTitle} />} />
                <Route path={`${path}/${entryTitleURL}`} component={() =>
                    <BlogEntry
                        id={entry.id}
                        month={entry.month}
                        day={entry.day}
                        year={entry.year}
                        title={entry.title}
                        content={entry.content}
                        isAuthenticated={props.isAuthenticated}
                        switchTitle={switchTitle}
                        closeModal={toggleNewBlogEntry} />} />
            </Switch>

        </li>
        );
    });

    //___________________________________________________
    //                 [[[  THE RETURN  ]]]
    //
    return (
        <div className={classes.backgroundCard}>
            {showNewBlogModal ? <BlogEntry addEntry={true} title='' content='' day='' month='' year='' closeModal={toggleNewBlogEntry} switchTitle={switchTitle} /> : null}
            <h1 className={classes.title}>
                {location === '/blog' ? 'Blog.' : currentTitle}
                {props.isAuthenticated ? <AddWidget clickHandler={toggleNewBlogEntry} /> : null}
            </h1>
            <hr />
            <section>
                <ul className={blogClasses.blogList}>
                    {entryCardList}
                </ul>
            </section>
        </div>

    );
}

export default Blog;