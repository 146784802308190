import React from 'react';
import classes from './WelcomeScreen.module.css';

const WelcomeScreen = () => {
    return (
        <div className={classes.welcomeContainer}>
            <div className={classes.welcomeContent}>
                <div className={classes.welcomeMessage}>Hello World!</div>
            </div>
        </div>
    )
}

export default WelcomeScreen;