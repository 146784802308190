import React, { useState, useEffect, Suspense } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Home from './containers/Home/Home';
import Portfolio from './containers/Portfolio/Portfolio';
import Blog from './containers/Blog/Blog';
import Login from './containers/Login/Login';
import Layout from './hoc/Layout/Layout';
import axiosInstance from './axios-instance';
import firebase from 'firebase/app';
import 'firebase/storage';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import WelcomeScreen from './components/WelcomeScreen/WelcomeScreen';

const firebaseConfig = {
  apiKey: "AIzaSyBTKKwVqfvF3n70HL8DGweVPDgJ3DmWKXs",
  authDomain: "my-website-mr.firebaseapp.com",
  databaseURL: "https://my-website-mr.firebaseio.com",
  projectId: "my-website-mr",
  storageBucket: "my-website-mr.appspot.com",
  messagingSenderId: "826825662860",
  appId: "1:826825662860:web:7d070df1c4c08adba1f6a9"
};

firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage().ref();

const App = () => {

  //___________________________________________________
  //---------------------------------------------------
  //                         STATE 
  //
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [blogEntries, setBlogEntries] = useState([]);
  const [portfolioEntries, setPortfolioEntries] = useState([]);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);

  //___________________________________________________
  //---------------------------------------------------
  //                     USE EFFECT 
  //
  // Loading portfolio entries
  useEffect(() => {
    axiosInstance.get('/portfolio.json')
      .then(response => {
        const fetchedPortfolioEntries = [];
        for (let key in response.data) {
          fetchedPortfolioEntries.push({
            ...response.data[key],
            id: key,
          });
        }
        setPortfolioEntries(fetchedPortfolioEntries);
      });
  }, []);

  // Loading blog entries
  useEffect(() => {
    axiosInstance.get('/blog.json')
      .then(response => {
        const fetchedBlogEntries = [];
        for (let key in response.data) {
          fetchedBlogEntries.push({
            ...response.data[key],
            id: key
          });
        }
        setBlogEntries(fetchedBlogEntries);
      });
  }, []);

  // Turn off WelcomeScreen
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWelcomeScreen(false)
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  //___________________________________________________
  //---------------------------------------------------
  //                      ROUTING 
  //
  const routes = (
    <TransitionGroup>
      <CSSTransition
        timeout={450}
        classNames='fade'
        key={useLocation().key}
      >
        <Switch location={useLocation()}>
          <Route path='/portfolio' exact component={() => <Portfolio portfolioEntries={portfolioEntries} isAuthenticated={isAuthenticated} />} />
          <Route path='/blog' component={() => <Blog blogEntries={blogEntries} isAuthenticated={isAuthenticated} />} />
          <Route path='/mi-hello' exact component={() => <Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path='/' exact component={() => <Home latestBlog={blogEntries[0]} latestWork={portfolioEntries[0]} />} />
          <Redirect to='/' />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );

  //___________________________________________________
  //---------------------------------------------------
  //                         JSX 
  //

  return (
    <React.Fragment>
      <CSSTransition
        in={showWelcomeScreen}
        classNames="welcome"
        timeout={300}
        unmountOnExit
      >
        <WelcomeScreen />
      </CSSTransition>
      <Layout>
        <Suspense fallback={<p>Loading...</p>}>
          {routes}
        </Suspense>
      </Layout>
    </React.Fragment>
  );

}

export default App;
