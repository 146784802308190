import React, { useState } from 'react';
import classes from '../../index.module.css';
import loginClasses from './Login.module.css';
import axios from 'axios';

const Login = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onChangeHandler = (event, setter) => {
        setter(event.target.value);
    }

    const signInHandler = () => {
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        };
        axios.post(process.env.REACT_APP_SIGN_IN_URL,
            authData)
            .then(response => {
                if (response.status === 200) {
                    props.setIsAuthenticated(true);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setEmail('');
                setPassword('');
            });

    }
    return (
        <div className={classes.backgroundCard}>
            <h1 className={loginClasses.title}>Login.</h1>
            <hr />
            <div className={loginClasses.formContainer}>
                <input
                    className={loginClasses.formElement}
                    type='email'
                    id='email'
                    value={email}
                    onChange={(e) => onChangeHandler(e, setEmail)}
                    placeholder='email'
                />
                <input
                    className={loginClasses.formElement}
                    type='password'
                    value={password}
                    onChange={(e) => onChangeHandler(e, setPassword)}
                    placeholder='password'
                />
                <button
                    className={loginClasses.formButton}
                    onClick={signInHandler}>
                    Log In
                    </button>
            </div>
        </div>
    );
}

export default Login;