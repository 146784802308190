import React, { useState, useEffect } from 'react';
import classes from './BlogEntry.module.css';
import axiosInstance from '../../axios-instance';
import ReactMarkdown from 'react-markdown';

import { EditWidget, DeleteWidget, SaveWidget, CloseWidget } from '../UI/Widgets/Widgets';

const BlogEntry = (props) => {

    const [blogEntryTitle] = useState(props.title);
    const [blogEntryContent] = useState(props.content);
    const [blogEntryMonth] = useState(props.month);
    const [blogEntryDay] = useState(props.day);
    const [blogEntryYear] = useState(props.year);

    const [updatedBlogEntryTitle, setUpdatedBlogEntryTitle] = useState(blogEntryTitle);
    const [updatedBlogEntryContent, setUpdatedBlogEntryContent] = useState(blogEntryContent);
    const [updatedBlogEntryMonth, setUpdatedBlogEntryMonth] = useState(blogEntryMonth);
    const [updatedBlogEntryDay, setUpdatedBlogEntryDay] = useState(blogEntryDay);
    const [updatedBlogEntryYear, setUpdatedBlogEntryYear] = useState(blogEntryYear);

    const [editing, setEditing] = useState(false);


    useEffect(() => {
        props.switchTitle(props.title)
    }, [props]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const toggleEdit = () => {
        setEditing(!editing);
    }

    const exitEdit = () => {
        const confirmExit = window.confirm("Sure you saved?");
        if (confirmExit) {
            props.addEntry ? props.closeModal() : toggleEdit();
        }
    }
    const onChangeHandler = (event, setter) => {
        setter(event.target.value);
    }

    const handleDeleteEntry = () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this entry?");
        if (confirmDelete) {
            axiosInstance.delete(`/blog/${props.id}.json`)
                .then(response => console.log(response.data));
        }
    }

    const handleSaveUpdateEntry = () => {
        let entry = ({
            month: updatedBlogEntryMonth,
            day: updatedBlogEntryDay,
            year: updatedBlogEntryYear,
            title: updatedBlogEntryTitle,
            content: updatedBlogEntryContent,
        });

        if (props.addEntry) {
            axiosInstance.post('/blog.json', entry)
                .then(response => {
                    if (response.status === 200) {
                        alert("successful save!");
                    }
                }).catch(error => {
                    console.log(error);
                });
        }
        else if (!props.addEntry) {
            axiosInstance.put(`/blog/${props.id}.json`, entry)
                .then(response => {
                    if (response.status === 200) {
                        alert("successful save!");
                    }
                }).catch(error => {
                    console.log(error);
                });;
        }
    }


    const editWidgets = (
        <React.Fragment>
            <EditWidget clickHandler={toggleEdit} />
            <DeleteWidget clickHandler={handleDeleteEntry} />
        </React.Fragment>
    );

    const blogEntry = (
        <React.Fragment>
            {props.isAuthenticated ? editWidgets : null}
            <ReactMarkdown source={blogEntryContent} />
        </React.Fragment>
    );

    const editEntry = (
        <div className={classes.modalBackground}>
            <div className={classes.modalContent}>
                <CloseWidget clickHandler={exitEdit} />
                <SaveWidget clickHandler={handleSaveUpdateEntry} />
                < article className={classes.portfolioEntryCard} >
                    <label
                        htmlFor='month'>
                        Month
                    </label>
                    <input
                        type='text'
                        id='month'
                        placeholder='DEC'
                        value={updatedBlogEntryMonth}
                        onChange={(e) => onChangeHandler(e, setUpdatedBlogEntryMonth)}
                        className={classes.blogDateEdit}>
                    </input>
                    <label
                        htmlFor='day'>
                        Day
                    </label>
                    <input
                        type='text'
                        id='day'
                        placeholder='31'
                        value={updatedBlogEntryDay}
                        onChange={(e) => onChangeHandler(e, setUpdatedBlogEntryDay)}
                        className={classes.blogDateEdit}>
                    </input>
                    <label
                        htmlFor='year'>
                        Year
                    </label>
                    <input
                        type='text'
                        id='year'
                        placeholder='1999'
                        value={updatedBlogEntryYear}
                        onChange={(e) => onChangeHandler(e, setUpdatedBlogEntryYear)}
                        className={classes.blogDateEdit}>
                    </input>
                    <hr />
                    <label
                        htmlFor='title'>
                        Title
                    </label>
                    <input
                        type='text'
                        id='title'
                        placeholder='blog entry title'
                        value={updatedBlogEntryTitle}
                        onChange={(e) => onChangeHandler(e, setUpdatedBlogEntryTitle)}
                        className={classes.blogTitleEdit}>
                    </input>
                    <hr />
                    <label
                        htmlFor='content'>
                        Description
                    </label>
                    <textarea
                        id='content'
                        placeholder='blog entry content...'
                        value={updatedBlogEntryContent}
                        onChange={(e) => onChangeHandler(e, setUpdatedBlogEntryContent)}
                        className={classes.blogContentEdit}>
                    </textarea>
                    <hr />
                </article >
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {editing || props.addEntry ? editEntry : blogEntry}
        </React.Fragment>
    );
}

export default BlogEntry;