import React, { useState } from 'react';
import classes from '../../index.module.css';
import portfolioClasses from './Portfolio.module.css';

import PortfolioCard from '../../components/Cards/PortfolioCard/PortfolioCard';
import PortfolioModal from '../../components/Modals/PortfolioModal/PortfolioModal';
import { AddWidget } from '../../components/UI/Widgets/Widgets';


const Portfolio = (props) => {

    //___________________________________________________
    //                   [[[  STATE  ]]]
    //
    const [showModal, toggleModal] = useState(false);

    //___________________________________________________
    //                  [[[  TOGGLES  ]]]
    //
    const togglePortfolioModal = () => toggleModal(!showModal);

    //___________________________________________________
    //                    [[[  JSX  ]]]
    //

    const entryCardList = props.portfolioEntries.map((entry) => {
        return (<li key={entry.id}>
            <PortfolioCard
                id={entry.id}
                imagePath={entry.imgSrc}
                title={entry.title}
                description={entry.description}
                isAuthenticated={props.isAuthenticated}
                imageURL={entry.imageURL}
            />
        </li>
        );
    });

    const portfolioModal = (
        <PortfolioModal
            entryInfo={['', '', '']}
            isAuthenticated={props.isAuthenticated}
            closeModal={togglePortfolioModal}
            addEntry={true}
        />
    );

    //___________________________________________________
    //---------------------------------------------------
    //                     THE RETURN 
    //
    return (
        <div className={classes.backgroundCard}>
            {showModal ? portfolioModal : null}
            <h1 className={classes.title}>
                My Work.
                {props.isAuthenticated ? <AddWidget clickHandler={togglePortfolioModal} /> : null}
            </h1>
            < hr />
            {props.portfolioEntries.length === 0 ? <p>No projects loaded...</p> :
                <ul className={portfolioClasses.portfolioList}>
                    {entryCardList}
                </ul>
            }
        </div >
    );
}

export default Portfolio;