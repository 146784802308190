import React, { useState } from 'react';
import classes from './PortfolioModal.module.css';
import axiosInstance from '../../../axios-instance';
import ReactMarkdown from 'react-markdown';

import { CloseWidget, EditWidget, SaveWidget, DeleteWidget } from '../../UI/Widgets/Widgets';
import Image from '../../../components/UI//Image/Image';

const PortfolioModal = (props) => {

    //___________________________________________________
    //---------------------------------------------------
    //                         STATE 
    //

    // This gets passed in from Portfolio or PortfolioCard
    const entryID = useState(props.id)[0]
    const entryImageSource = useState(props.imagePath)[0] //first element is img source
    const entryTitle = useState(props.title)[0] //second element is entry title
    const entryDescription = useState(props.description)[0] //third element is description

    // These get used for when the content is being edited,
    //  such that the original does not get changed unless
    //  an axios request is sent to the backend.
    const [updatedEntryImageSource, setUpdatedEntryImageSource] = useState(entryImageSource);
    const [updatedEntryTitle, setUpdatedEntryTitle] = useState(entryTitle);
    const [updatedEntryDescription, setUpdatedEntryDescription] = useState(entryDescription);

    // Used to toggle which JSX is shown: "portfolioEntry" or "editEntry"
    const [editing, setEditing] = useState(false);

    //___________________________________________________
    //---------------------------------------------------
    //                         TOGGLES 
    //

    const toggleEdit = () => {
        setEditing(!editing);
    }

    // When clicking the 'X' to get out of "editEntry" component
    //  it confirms if you've saved. If you continue it will
    //  check if you came at "editEntry" component by clicking
    //  the '+' button in PORTFOLIO or through PORTFOLIOMODAL.
    // If you came from PORTFOLIO it will just close the modal.
    // If you came from PORTFOLIOMODAL it closes "editEntry" and
    //  shows you "portfolioEntry"
    const exitEdit = () => {
        const confirmExit = window.confirm("Sure you saved?");
        if (confirmExit) {
            props.addEntry ? props.closeModal() : toggleEdit();
        }
    }

    // Click on modal background to close modal
    window.onclick = function (event) {
        if (event.target === document.getElementById("modal")) {
            props.closeModal();
        }
    }

    //___________________________________________________
    //---------------------------------------------------
    //                         HANDLERS 
    //

    const onChangeHandler = (event, setter) => {
        setter(event.target.value);
    }

    const handleSaveUpdateEntry = () => {
        let entry = ({
            imgSrc: updatedEntryImageSource,
            title: updatedEntryTitle,
            description: updatedEntryDescription,
        });
        if (props.addEntry) {
            axiosInstance.post('/portfolio.json', entry)
                .then(response => {
                    if (response.status === 200) {
                        alert("successful save!");
                    }
                }).catch(error => {
                    console.log(error);
                });
        }
        else if (!props.addEntry) {
            axiosInstance.put(`/portfolio/${entryID}.json`, entry)
                .then(response => {
                    if (response.status === 200) {
                        alert("successful save!");
                    }
                }).catch(error => {
                    console.log(error);
                });;
        }
    }

    const handleDeleteEntry = () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this entry?");
        if (confirmDelete) {
            axiosInstance.delete(`/portfolio/${entryID}.json`)
                .then(response => console.log(response.data));
            props.closeModal();
        }
    }

    //___________________________________________________
    //---------------------------------------------------
    //                         JSX 
    //

    const editWidgets = (
        <div className={classes.modalWidgetPlacement}>
            <EditWidget clickHandler={toggleEdit} />
            <DeleteWidget clickHandler={handleDeleteEntry} />
        </div>
    );

    // Shown when VIEWING
    const portfolioEntry = (
        <div id="modal" className={classes.modalBackground}>
            <div className={classes.modalContent} >
                <CloseWidget clickHandler={props.closeModal} />
                {props.isAuthenticated ? editWidgets : null}
                < article className={classes.portfolioEntryCard} >
                    <div className={classes.portfolioImageContainer}>
                        <Image className={classes.portfolioEntryImage} imagePath={props.imagePath} alt='Project Demo' />
                    </div>

                    <h2 className={classes.portfolioEntryTitle}>{entryTitle}</h2>
                    <hr />
                    <ReactMarkdown className={classes.portfolioEntryDescription} source={entryDescription} />
                </article >
            </div>
        </div>
    );

    // Shown when ADDING or EDITING
    const editEntry = (
        <div className={classes.modalBackground}>
            <div className={classes.modalContent}>
                <CloseWidget clickHandler={exitEdit} />
                <SaveWidget clickHandler={handleSaveUpdateEntry} />
                < article className={classes.portfolioEntryCard} >
                    <label
                        htmlFor='imageSource'>
                        Image Source
                    </label>
                    <input
                        type='text'
                        id='imageSource'
                        placeholder="source from firebase"
                        value={updatedEntryImageSource}
                        onChange={(e) => onChangeHandler(e, setUpdatedEntryImageSource)}
                        className={classes.portfolioImageEdit}>
                    </input>
                    <hr />
                    <label
                        htmlFor='title'>
                        Title
                    </label>
                    <input
                        type='text'
                        id='title'
                        placeholder='project title'
                        value={updatedEntryTitle}
                        onChange={(e) => onChangeHandler(e, setUpdatedEntryTitle)}
                        className={classes.portfolioTitleEdit}>
                    </input>
                    <hr />
                    <label
                        htmlFor='description'>
                        Description
                    </label>
                    <textarea
                        id='description'
                        placeholder='description of project/work'
                        value={updatedEntryDescription}
                        onChange={(e) => onChangeHandler(e, setUpdatedEntryDescription)}
                        className={classes.portfolioDescriptionEdit}>
                    </textarea>
                    <hr />
                </article >
            </div>
        </div>
    )

    //___________________________________________________
    //---------------------------------------------------
    //                     THE RETURN 
    //

    return (
        <React.Fragment>
            {editing || props.addEntry ? editEntry : portfolioEntry}
        </React.Fragment>
    );
}

export default PortfolioModal;