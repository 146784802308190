import React, { useEffect, useState } from 'react';

import { storage } from '../../../App';
import Spinner from '../Loading/Spinner';
import missingImage from '../../../assets/missingImage.png';

const Image = (props) => {

    const [loading, setLoading] = useState(true);
    const [imageURL, setImageURL] = useState('');
    const [error, setError] = useState(false);

    const getAndSetImageURL = (imagePath) => {
        storage.child(imagePath).getDownloadURL()
            .then((url) => {
                setImageURL(url)
            })
            .catch((error) => {
                setImageURL(missingImage);
                setError(true)
            });
    }

    useEffect(() => {
        if (props.imagePath !== undefined) {
            getAndSetImageURL(props.imagePath);
            setLoading(false);
        }
    }, [props.imagePath]);

    return (
        <React.Fragment>
            {loading ? <Spinner /> :
                <img
                    className={props.className}
                    src={imageURL}
                    alt={props.alt}
                />
            }
            {error ? "Something went wrong :/" : null}
        </React.Fragment>
    )
}

export default Image;