import React, { useEffect, useState } from 'react';
import classes from './PortfolioCard.module.css';
import ReactMarkdown from 'react-markdown';
import { CSSTransition } from 'react-transition-group';

import PortfolioModal from '../../Modals/PortfolioModal/PortfolioModal';
import Spinner from '../../UI/Loading/Spinner';
import Image from '../../UI/Image/Image';

//NOTE: Edit and Delete functions are in the PortfolioModal component
const PortfolioCard = (props) => {

    const [showModal, toggleModal] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props) {
            setLoading(false);
        }
    }, [props])

    const handlePortfolioEntryClick = () => {
        toggleModal(!showModal);
    }

    const portfolioModal = (
        <PortfolioModal
            closeModal={handlePortfolioEntryClick}
            id={props.id}
            imagePath={props.imagePath}
            title={props.title}
            description={props.description}
            isAuthenticated={props.isAuthenticated}
        />
    )

    const portfolioCard = (
        <React.Fragment>
            <CSSTransition
                in={showModal}
                classNames="modal"
                timeout={300}
                unmountOnExit
            >
                {portfolioModal}
            </CSSTransition>
            {loading ? <Spinner /> :
                < article className={classes.portfolioEntryCard} onClick={handlePortfolioEntryClick} >
                    <Image className={classes.portfolioEntryImage} imagePath={props.imagePath} alt="Project Demo" />
                    <h2 className={classes.portfolioEntryTitle}>{props.title}</h2>
                    <ReactMarkdown className={classes.portfolioEntryDescription} source={props.description} />
                </article >
            }
        </React.Fragment>
    );

    const portfolioCardHome = (
        <React.Fragment>
            <CSSTransition
                in={showModal}
                classNames="modal"
                timeout={300}
                unmountOnExit
            >
                {portfolioModal}
            </CSSTransition>
            {loading ? <Spinner /> :
                < article className={classes.portfolioEntryCardHome} onClick={handlePortfolioEntryClick} >
                    <h2 className={classes.portfolioEntryTitleHome}>{props.title}</h2>
                    <Image className={classes.portfolioEntryImage} imagePath={props.imagePath} alt="Project Demo" />
                </article >
            }
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {props.homePage ? portfolioCardHome : portfolioCard}
        </React.Fragment>
    );
}

export default PortfolioCard;