import React from 'react';
import classes from './DateSquare.module.css';

const DateSquare = (props) => {

    const dateSquare = (
        <div className={classes.box}>
            <div className={classes.month}>
                {props.month}
            </div>
            <div className={classes.dayYearCombo}>
                {`${props.day}/${props.year}`}
            </div>
        </div>
    );

    const dateSquareHome = (
        <div className={classes.box}>
            <div className={classes.month}>
                {props.month}
            </div>
            <div className={classes.dayYearCombo}>
                {`${props.day}/${props.year}`}
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {props.homePage ? dateSquareHome : dateSquare}
        </React.Fragment>
    );
}

export default DateSquare;