import React from 'react';
import classes from './BlogCard.module.css';
import { useHistory } from 'react-router-dom';

import DateSquare from '../../UI/DateSquare/DateSquare';

const BlogCard = (props) => {

    const history = useHistory();

    const handleBlogEntryClick = (title) => {
        props.switchTitle && props.switchTitle(title);
        const cleanedTitle = title.replace(/\s/g, "-").toLowerCase();
        history.push(`blog/${cleanedTitle}`);
    }

    const blogCard = (
        < article className={classes.blogEntryCard} onClick={() => handleBlogEntryClick(props.title)} >
            <DateSquare month={props.month} day={props.day} year={props.year} />
            <div className={classes.blogEntryInfo}>
                <h2 className={classes.blogEntryTitle}>{props.title}</h2>
                <p className={classes.blogEntryContent}>
                    {props.content}
                </p>
            </div>
        </article >
    );

    const blogCardHome = (
        < article className={classes.blogEntryCardHome} onClick={() => handleBlogEntryClick(props.title)} >
            <h2 className={classes.blogEntryTitleHome}>{props.title}</h2>
            <h3 className={classes.blogEntryDateHome}>{`${props.month} ${props.day}, ${props.year}`}</h3>
            <p className={classes.blogEntryContentHome}>
                {props.content}
            </p>
        </article >
    );

    return (
        <React.Fragment>
            {props.homePage ? blogCardHome : blogCard}
        </React.Fragment>
    );
}

export default BlogCard;